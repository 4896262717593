import React from 'react';
import {technologies,TechnologyIcon} from './Technologies.jsx';
import {AniMovElem} from './Animations.jsx';
import {DropdownSelect} from './FormFields.jsx';


export class Projects extends React.Component {
	
	constructor(props) {
		super(props);

		this.sortOptions = ['Newest','Oldest','A-Z'];
		this.filterOptions = ['All'];

		if (technologies !== undefined) {
			for (let tech in technologies) {
				this.filterOptions.push(technologies[tech].hrtitle);
			}
		}

		this.state = {
			'initProjects' 	: window.projects || false,
			'projects' 		: false,
			'sortType' 		: this.sortOptions[0],
			'filterType' 	: this.filterOptions[0],
		}

		if (this.state.initProjects) {
			for (let proj in this.state.initProjects) {
				this.state.initProjects[proj].dateTick = new Date(this.state.initProjects[proj].date).getTime();
			}
		}

		this.handleSortChange = this.handleSortChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
	}

	componentDidMount() {
		if (this.state.initProjects) {
			this.filterSort();
		}
	}

	filterSort(filterType, sortType) {
		
		const data = this.state.initProjects;

		if (filterType === undefined || filterType === false) {
			filterType = this.state.filterType;
		}

		if (sortType === undefined || sortType === false) {
			sortType = this.state.sortType;
		}


		let newData = data.filter(function(obj) {
			if (obj.active) {
				if (filterType === 'All' || filterType === 'all' || obj.technologies.indexOf(filterType) !== -1) {
					return true;
				}
			}
			return false;
		});


		if (sortType === 'newest') {
			newData.sort(function (a, b) {
				return b.dateTick - a.dateTick;
			});
		}
		else if (sortType === 'oldest') {
			newData.sort(function (a, b) {
				return a.dateTick - b.dateTick;
			});
		}
		else if (sortType === 'a_z') {
			newData.sort(function (a, b) {
				if (a.title < b.title) {
					return -1;
				}
				else if (a.title > b.title) {
					return 1;
				}
				return 0;
			});
		}

		this.setState({
			'projects' 		: newData,
			'sortType' 		: sortType,
			'filterType' 	: filterType,
		});

	}

	handleSortChange(e) {
		this.filterSort(this.state.filterType, e.target.value);
	}

	handleFilterChange(e) {
		this.filterSort(e.target.value, this.state.sortType);
	}

	render() {
		return (
			<div className="projects">
				<div className="projects__header">
					<div className="projects__header__left pull-left">
						<h1>Projects</h1>{(this.state.projects && this.state.projects.length) && <i className="projects__count">{this.state.projects.length}</i>}
					</div>
					<div className="projects__header__right pull-right">
						<DropdownSelect onChange={this.handleSortChange} label="Sort by" options={this.sortOptions} value={this.state.sortType} />
						<DropdownSelect onChange={this.handleFilterChange} label="Show" options={this.filterOptions} value={this.state.filterType} />
					</div>
				</div>	

				{(this.state.projects && this.state.projects.length)
					? 
					this.state.projects.map(
						(project, index) => {
							project.safeTitle = project.title.replace(/[^a-z]/gi, '').toLowerCase();
							return (
								<AniMovElem key={project.safeTitle}>
									<Project data={project} filterType={this.state.filterType} />
								</AniMovElem>
							)
						}
					)
					: 
					<p>There are no projects to display.</p>
				}

			</div>
		);
	}

}

class Project extends React.Component {
	render() {
		const data = this.props.data;
		let displayUrl = data.url.replace(/^(https|http):\/\//, '');
		return (
			<article ref={this.props.forwardedRef} className={'projects__project row project--' + data.safeTitle}>
				<summary className="col-xs-60 col-sm-60 col-md-30">
					{(data.logo) && <div className="project__logo "><img src={data.logo} alt={data.title} /></div>}
					<h1 className="project__title ">{data.title}</h1>
					<div className="project__icons ">{data.technologies.map((t, index) => { 
						return (
							<TechnologyIcon key={index} icon={t} active={(this.props.filterType === t) ? true : false} />
						); 
					})}</div>
					<div className="project__description" >{data.description.split('\n').map((d, index) => {return (<p key={index}>{d}</p>); })}</div>
					<a className="project__link" href={data.url} target="_blank" rel="noopener noreferrer">{displayUrl}</a>
				</summary>
				<figure className="col-xs-60 col-sm-60 col-md-30" >
					<a className="project__preview" href={data.url} target="_blank" rel="noopener noreferrer">
							<img src={data.preview} alt={data.title} />
							<span className="project__preview__link"><i>{displayUrl}</i></span>
					</a>
				</figure>
			</article>
		);
	}
}