import React from 'react';
import {Intro} from './components/Intro.jsx';
import {Projects} from './components/Projects.jsx';

function App() {
  return (
    <main className="container">
      <Intro />
      <Projects />
    </main>
  );
}

export default App;
