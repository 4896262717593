import React from 'react';
import {IconHtml,IconCss,IconJavascript,IconPhp,IconMysql,IconCodeigniter,IconBootstrap,IconReact} from './Icons.jsx';


export const technologies = {
	'html' : { 
		'hrtitle' 	: 'Html', 
		'svg' 		: <IconHtml />
	},
	'css' : { 
		'hrtitle' 	: 'Css', 
		'svg' 		: <IconCss />
	},
	'javascript' : { 
		'hrtitle' 	: 'Javascript', 
		'svg' 		: <IconJavascript />
	},
	'php' : { 
		'hrtitle' 	: 'Php', 
		'svg' 		: <IconPhp />
	},
	'mysql' : { 
		'hrtitle' 	: 'Mysql', 
		'svg' 		: <IconMysql />
	},
	'codeigniter' : { 
		'hrtitle' 	: 'Codeigniter', 
		'svg' 		: <IconCodeigniter />
	},
	'bootstrap' : { 
		'hrtitle' 	: 'Bootstrap', 
		'svg' 		: <IconBootstrap />
	},
	'react' : { 
		'hrtitle' 	: 'React', 
		'svg' 		: <IconReact />
	},
}


export const TechnologyIcon = function(props) {

	if (props.icon && technologies[props.icon] !== undefined) {
		return (
			<div className={'technology_icon' + ((props.active) ? ' technology_icon--active' : '')}>
				{technologies[props.icon].svg}
				<span>{technologies[props.icon].hrtitle}</span>
			</div>
		);
	}

	return null;
}