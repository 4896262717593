import React from 'react';

export class AniMovElem extends React.Component {
	constructor(props) {
		super(props);
		this.elem = React.createRef();
		
		this.animateIn = 1000;
		this.animateOut = 1000;

		if (this.props.animateIn) {
			this.animateIn = this.props.animateIn;
		}

		if (this.props.animateOut) {
			this.animateOut = this.props.animateOut;
		}

		this.timer = false;
	}

	componentDidMount() {
		const elem = this.elem.current;
		const animateIn = this.animateIn / 1000;

		this.timer = setTimeout(function() {
			const computed = window.getComputedStyle(elem);
			this.posLeft = elem.offsetLeft - parseFloat(computed['marginLeft']);
			this.posTop = elem.offsetTop - parseFloat(computed['marginTop']);
		}.bind(this), this.animateIn * 1.2);

		requestAnimationFrame(function() {
			elem.style.transition = 'all 0s ease 0s';
			elem.style.opacity = '0';  
			
			requestAnimationFrame(function() {
				elem.style.transition = 'opacity ' + animateIn + 's ease 0.3s';  
				elem.style.opacity = '1';
			});

		});
	}

	getSnapshotBeforeUpdate() {
		const elem = this.elem.current;

		const snapshot = {
			'top': elem.offsetTop,
			'left': elem.offsetLeft,
		};

		return snapshot;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const elem = this.elem.current;

		const animateIn = this.animateIn / 1000;
		const oldPos = snapshot;

		const computed = window.getComputedStyle(elem);
		this.posLeft = elem.offsetLeft - parseFloat(computed['marginLeft']);
		this.posTop = elem.offsetTop - parseFloat(computed['marginTop']);

		const newPos = {
			'top': elem.offsetTop,
			'left': elem.offsetLeft,
		};

		const diffX = oldPos.left - newPos.left;
		const diffY = oldPos.top - newPos.top; 

		requestAnimationFrame(function() {
			elem.style.transition = 'transform 0s ease 0s';  
			elem.style.transform = 'translate(' + diffX + 'px, ' + diffY + 'px)';

			requestAnimationFrame(function() {
				elem.style.transition = 'transform ' + animateIn + 's';
				elem.style.transform  = '';
			});

		});
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
		const elem = this.elem.current;
		const elemParent = elem.parentNode;
		const animateOut = this.animateOut / 1000;
		
		const duplicatedElem = elem.cloneNode(true);

		duplicatedElem.style.position = 'absolute';
		duplicatedElem.style.top = this.posTop + 'px';
		duplicatedElem.style.right = 'auto';
		duplicatedElem.style.bottom = 'auto';
		duplicatedElem.style.left = this.posLeft + 'px';

		const placeholderElem = elemParent.appendChild(duplicatedElem);

		requestAnimationFrame(function() {
			placeholderElem.style.transition = 'all 0s ease 0s';
			placeholderElem.style.opacity = '1';  

			requestAnimationFrame(function() {
				placeholderElem.style.transition = 'opacity ' + animateOut + 's ease 0s';  
				placeholderElem.style.opacity = '0';
			});

		});

		setTimeout(function() {
			elemParent.removeChild(placeholderElem);
		}, this.animateOut);
	}

	render() {
		return React.cloneElement(this.props.children, { forwardedRef: this.elem });
	}
}