import React from 'react';


export class Intro extends React.Component {
	
	constructor(props) {
		super(props);

		this.blurb = window.intro || false;
	}

	render() {
		return (
			<div className="intro">
				<div className="intro__badge"><img src="/static/images/profile.png" alt="Jonathan Grasso" /></div>
				<h1>Jonathan Grasso</h1>
				{(this.blurb) && this.blurb.split('\n').map((b, index) => {return (<p key={index}>{b}</p>); })}
			</div>
		);
	}
	
}