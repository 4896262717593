import React from 'react';
import {IconSelectDown} from './Icons.jsx';


export class DropdownSelect extends React.Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);

		//options are an array of strings or an array of objects {title:'', value:''}

		let options = props.options;

		options.map(function(opt, index) {
			if (typeof opt === 'string') {
				options[index] = {
					'title' : opt,
					'value' : opt.toLowerCase().replace(/[^a-z]/gi,'_'),
				};			
			}
			return false;
		});

		this.options = options;

		this.defaultVal = props.value.toLowerCase().replace(/[^a-z]/gi,'_') || options[0].value;
	}

	handleChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
		
		if (this.props.valueUpdateCallback) {
			this.props.valueUpdateCallback(this.props.name, e.target.value);
		}
	}

	componentDidMount() {
		if (this.props.valueUpdateCallback && this.defaultVal) {
			this.props.valueUpdateCallback(this.props.name, this.defaultVal);
		}
	}

	render() {
		if (this.options) {
			return (
				<div className={'custom-select' + ((this.props.label) ? ' custom-select--w-label': '')}>
					{this.props.label && <label>{this.props.label}</label>}
					<i><IconSelectDown /></i>
					<select name={this.props.name} onChange={this.handleChange} defaultValue={this.defaultVal}>
					{this.options.map(function(opt, index) {
						return (
							<option key={index} value={opt.value} >{opt.title}</option>
						);
					})}
					</select>
				</div>
			);
		}
		else {
			return null;
		}
	}
}